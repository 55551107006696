import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTNewAddressModal = _resolveComponent("KTNewAddressModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "New Address Modal Example",
      description: "Click on the below buttons to launch <br/>a new address example.",
      image: _ctx.getIllustrationsPath('19.png'),
      "button-text": "Add New Address",
      "modal-id": "kt_modal_new_address"
    }, null, 8, ["image"]),
    _createVNode(_component_KTNewAddressModal)
  ], 64))
}