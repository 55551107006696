import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  ref: "newAddressModalRef",
  id: "kt_modal_new_address",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_new_address_header"
}
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_8 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_new_address_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_new_address_header",
  "data-kt-scroll-wrappers": "#kt_modal_new_address_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_9 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6" }
const _hoisted_10 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }
const _hoisted_11 = { class: "row mb-5" }
const _hoisted_12 = { class: "col-md-6 fv-row" }
const _hoisted_13 = { class: "fv-plugins-message-container" }
const _hoisted_14 = { class: "fv-help-block" }
const _hoisted_15 = { class: "col-md-6 fv-row" }
const _hoisted_16 = { class: "fv-plugins-message-container" }
const _hoisted_17 = { class: "fv-help-block" }
const _hoisted_18 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_19 = { class: "fv-plugins-message-container" }
const _hoisted_20 = { class: "fv-help-block" }
const _hoisted_21 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_22 = { class: "fv-plugins-message-container" }
const _hoisted_23 = { class: "fv-help-block" }
const _hoisted_24 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_25 = { class: "fv-plugins-message-container" }
const _hoisted_26 = { class: "fv-help-block" }
const _hoisted_27 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_28 = { class: "fv-plugins-message-container" }
const _hoisted_29 = { class: "fv-help-block" }
const _hoisted_30 = { class: "row g-9 mb-5" }
const _hoisted_31 = { class: "col-md-6 fv-row" }
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "col-md-6 fv-row" }
const _hoisted_35 = { class: "fv-plugins-message-container" }
const _hoisted_36 = { class: "fv-help-block" }
const _hoisted_37 = { class: "fv-row mb-5" }
const _hoisted_38 = { class: "d-flex flex-stack" }
const _hoisted_39 = { class: "form-check form-switch form-check-custom form-check-solid" }
const _hoisted_40 = { class: "fv-plugins-message-container" }
const _hoisted_41 = { class: "fv-help-block" }
const _hoisted_42 = { class: "modal-footer flex-center" }
const _hoisted_43 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_new_address_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Form, {
          class: "form",
          id: "kt_modal_new_address_form",
          onSubmit: _ctx.submit,
          "validation-schema": _ctx.validationSchema
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Add New Address", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "d-flex flex-stack flex-grow-1" }, [
                    _createElementVNode("div", { class: "fw-bold" }, [
                      _createElementVNode("h4", { class: "text-gray-800 fw-bolder" }, "Warning"),
                      _createElementVNode("div", { class: "fs-6 text-gray-600" }, [
                        _createTextVNode(" Updating address may affter to your "),
                        _createElementVNode("a", { href: "#" }, "Tax Location")
                      ])
                    ])
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "First name", -1)),
                    _createVNode(_component_Field, {
                      type: "text",
                      class: "form-control form-control-solid",
                      placeholder: "",
                      name: "firstName",
                      modelValue: _ctx.newAddressData.firstName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAddressData.firstName) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_ErrorMessage, { name: "firstName" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Last name", -1)),
                    _createVNode(_component_Field, {
                      type: "text",
                      class: "form-control form-control-solid",
                      placeholder: "",
                      name: "lastName",
                      modelValue: _ctx.newAddressData.lastName,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newAddressData.lastName) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_ErrorMessage, { name: "lastName" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", { class: "d-flex align-items-center fs-5 fw-bold mb-2" }, [
                    _createElementVNode("span", { class: "required" }, "Country"),
                    _createElementVNode("i", {
                      class: "fas fa-exclamation-circle ms-2 fs-7",
                      "data-bs-toggle": "tooltip",
                      title: "Your payment statements may very based on selected country"
                    })
                  ], -1)),
                  _createVNode(_component_Field, {
                    name: "country",
                    class: "form-select form-select-solid",
                    as: "select",
                    modelValue: _ctx.newAddressData.country,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAddressData.country) = $event))
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("option", { value: "" }, "Select a Country...", -1),
                      _createElementVNode("option", { value: "AF" }, "Afghanistan", -1),
                      _createElementVNode("option", { value: "AX" }, "Aland Islands", -1),
                      _createElementVNode("option", { value: "AL" }, "Albania", -1),
                      _createElementVNode("option", { value: "DZ" }, "Algeria", -1),
                      _createElementVNode("option", { value: "AS" }, "American Samoa", -1),
                      _createElementVNode("option", { value: "AD" }, "Andorra", -1),
                      _createElementVNode("option", { value: "AO" }, "Angola", -1),
                      _createElementVNode("option", { value: "AI" }, "Anguilla", -1),
                      _createElementVNode("option", { value: "AQ" }, "Antarctica", -1),
                      _createElementVNode("option", { value: "AG" }, "Antigua and Barbuda", -1),
                      _createElementVNode("option", { value: "AR" }, "Argentina", -1),
                      _createElementVNode("option", { value: "AM" }, "Armenia", -1),
                      _createElementVNode("option", { value: "AW" }, "Aruba", -1),
                      _createElementVNode("option", { value: "AU" }, "Australia", -1),
                      _createElementVNode("option", { value: "AT" }, "Austria", -1),
                      _createElementVNode("option", { value: "AZ" }, "Azerbaijan", -1),
                      _createElementVNode("option", { value: "BS" }, "Bahamas", -1),
                      _createElementVNode("option", { value: "BH" }, "Bahrain", -1),
                      _createElementVNode("option", { value: "BD" }, "Bangladesh", -1),
                      _createElementVNode("option", { value: "BB" }, "Barbados", -1),
                      _createElementVNode("option", { value: "BY" }, "Belarus", -1),
                      _createElementVNode("option", { value: "BE" }, "Belgium", -1),
                      _createElementVNode("option", { value: "BZ" }, "Belize", -1),
                      _createElementVNode("option", { value: "BJ" }, "Benin", -1),
                      _createElementVNode("option", { value: "BM" }, "Bermuda", -1),
                      _createElementVNode("option", { value: "BT" }, "Bhutan", -1),
                      _createElementVNode("option", { value: "BO" }, "Bolivia, Plurinational State of", -1),
                      _createElementVNode("option", { value: "BQ" }, "Bonaire, Sint Eustatius and Saba", -1),
                      _createElementVNode("option", { value: "BA" }, "Bosnia and Herzegovina", -1),
                      _createElementVNode("option", { value: "BW" }, "Botswana", -1),
                      _createElementVNode("option", { value: "BV" }, "Bouvet Island", -1),
                      _createElementVNode("option", { value: "BR" }, "Brazil", -1),
                      _createElementVNode("option", { value: "IO" }, "British Indian Ocean Territory", -1),
                      _createElementVNode("option", { value: "BN" }, "Brunei Darussalam", -1),
                      _createElementVNode("option", { value: "BG" }, "Bulgaria", -1),
                      _createElementVNode("option", { value: "BF" }, "Burkina Faso", -1),
                      _createElementVNode("option", { value: "BI" }, "Burundi", -1),
                      _createElementVNode("option", { value: "KH" }, "Cambodia", -1),
                      _createElementVNode("option", { value: "CM" }, "Cameroon", -1),
                      _createElementVNode("option", { value: "CA" }, "Canada", -1),
                      _createElementVNode("option", { value: "CV" }, "Cape Verde", -1),
                      _createElementVNode("option", { value: "KY" }, "Cayman Islands", -1),
                      _createElementVNode("option", { value: "CF" }, "Central African Republic", -1),
                      _createElementVNode("option", { value: "TD" }, "Chad", -1),
                      _createElementVNode("option", { value: "CL" }, "Chile", -1),
                      _createElementVNode("option", { value: "CN" }, "China", -1),
                      _createElementVNode("option", { value: "CX" }, "Christmas Island", -1),
                      _createElementVNode("option", { value: "CC" }, "Cocos (Keeling) Islands", -1),
                      _createElementVNode("option", { value: "CO" }, "Colombia", -1),
                      _createElementVNode("option", { value: "KM" }, "Comoros", -1),
                      _createElementVNode("option", { value: "CG" }, "Congo", -1),
                      _createElementVNode("option", { value: "CD" }, " Congo, the Democratic Republic of the ", -1),
                      _createElementVNode("option", { value: "CK" }, "Cook Islands", -1),
                      _createElementVNode("option", { value: "CR" }, "Costa Rica", -1),
                      _createElementVNode("option", { value: "CI" }, "Côte d'Ivoire", -1),
                      _createElementVNode("option", { value: "HR" }, "Croatia", -1),
                      _createElementVNode("option", { value: "CU" }, "Cuba", -1),
                      _createElementVNode("option", { value: "CW" }, "Curaçao", -1),
                      _createElementVNode("option", { value: "CY" }, "Cyprus", -1),
                      _createElementVNode("option", { value: "CZ" }, "Czech Republic", -1),
                      _createElementVNode("option", { value: "DK" }, "Denmark", -1),
                      _createElementVNode("option", { value: "DJ" }, "Djibouti", -1),
                      _createElementVNode("option", { value: "DM" }, "Dominica", -1),
                      _createElementVNode("option", { value: "DO" }, "Dominican Republic", -1),
                      _createElementVNode("option", { value: "EC" }, "Ecuador", -1),
                      _createElementVNode("option", { value: "EG" }, "Egypt", -1),
                      _createElementVNode("option", { value: "SV" }, "El Salvador", -1),
                      _createElementVNode("option", { value: "GQ" }, "Equatorial Guinea", -1),
                      _createElementVNode("option", { value: "ER" }, "Eritrea", -1),
                      _createElementVNode("option", { value: "EE" }, "Estonia", -1),
                      _createElementVNode("option", { value: "ET" }, "Ethiopia", -1),
                      _createElementVNode("option", { value: "FK" }, "Falkland Islands (Malvinas)", -1),
                      _createElementVNode("option", { value: "FO" }, "Faroe Islands", -1),
                      _createElementVNode("option", { value: "FJ" }, "Fiji", -1),
                      _createElementVNode("option", { value: "FI" }, "Finland", -1),
                      _createElementVNode("option", { value: "FR" }, "France", -1),
                      _createElementVNode("option", { value: "GF" }, "French Guiana", -1),
                      _createElementVNode("option", { value: "PF" }, "French Polynesia", -1),
                      _createElementVNode("option", { value: "TF" }, "French Southern Territories", -1),
                      _createElementVNode("option", { value: "GA" }, "Gabon", -1),
                      _createElementVNode("option", { value: "GM" }, "Gambia", -1),
                      _createElementVNode("option", { value: "GE" }, "Georgia", -1),
                      _createElementVNode("option", { value: "DE" }, "Germany", -1),
                      _createElementVNode("option", { value: "GH" }, "Ghana", -1),
                      _createElementVNode("option", { value: "GI" }, "Gibraltar", -1),
                      _createElementVNode("option", { value: "GR" }, "Greece", -1),
                      _createElementVNode("option", { value: "GL" }, "Greenland", -1),
                      _createElementVNode("option", { value: "GD" }, "Grenada", -1),
                      _createElementVNode("option", { value: "GP" }, "Guadeloupe", -1),
                      _createElementVNode("option", { value: "GU" }, "Guam", -1),
                      _createElementVNode("option", { value: "GT" }, "Guatemala", -1),
                      _createElementVNode("option", { value: "GG" }, "Guernsey", -1),
                      _createElementVNode("option", { value: "GN" }, "Guinea", -1),
                      _createElementVNode("option", { value: "GW" }, "Guinea-Bissau", -1),
                      _createElementVNode("option", { value: "GY" }, "Guyana", -1),
                      _createElementVNode("option", { value: "HT" }, "Haiti", -1),
                      _createElementVNode("option", { value: "HM" }, "Heard Island and McDonald Islands", -1),
                      _createElementVNode("option", { value: "VA" }, "Holy See (Vatican City State)", -1),
                      _createElementVNode("option", { value: "HN" }, "Honduras", -1),
                      _createElementVNode("option", { value: "HK" }, "Hong Kong", -1),
                      _createElementVNode("option", { value: "HU" }, "Hungary", -1),
                      _createElementVNode("option", { value: "IS" }, "Iceland", -1),
                      _createElementVNode("option", { value: "IN" }, "India", -1),
                      _createElementVNode("option", { value: "ID" }, "Indonesia", -1),
                      _createElementVNode("option", { value: "IR" }, "Iran, Islamic Republic of", -1),
                      _createElementVNode("option", { value: "IQ" }, "Iraq", -1),
                      _createElementVNode("option", { value: "IE" }, "Ireland", -1),
                      _createElementVNode("option", { value: "IM" }, "Isle of Man", -1),
                      _createElementVNode("option", { value: "IL" }, "Israel", -1),
                      _createElementVNode("option", { value: "IT" }, "Italy", -1),
                      _createElementVNode("option", { value: "JM" }, "Jamaica", -1),
                      _createElementVNode("option", { value: "JP" }, "Japan", -1),
                      _createElementVNode("option", { value: "JE" }, "Jersey", -1),
                      _createElementVNode("option", { value: "JO" }, "Jordan", -1),
                      _createElementVNode("option", { value: "KZ" }, "Kazakhstan", -1),
                      _createElementVNode("option", { value: "KE" }, "Kenya", -1),
                      _createElementVNode("option", { ue: "KI" }, "Kiribati", -1),
                      _createElementVNode("option", { value: "KP" }, " Korea, Democratic People's Republic of ", -1),
                      _createElementVNode("option", { value: "KW" }, "Kuwait", -1),
                      _createElementVNode("option", { value: "KG" }, "Kyrgyzstan", -1),
                      _createElementVNode("option", { value: "LA" }, "Lao People's Democratic Republic", -1),
                      _createElementVNode("option", { value: "LV" }, "Latvia", -1),
                      _createElementVNode("option", { value: "LB" }, "Lebanon", -1),
                      _createElementVNode("option", { value: "LS" }, "Lesotho", -1),
                      _createElementVNode("option", { value: "LR" }, "Liberia", -1),
                      _createElementVNode("option", { value: "LY" }, "Libya", -1),
                      _createElementVNode("option", { value: "LI" }, "Liechtenstein", -1),
                      _createElementVNode("option", { value: "LT" }, "Lithuania", -1),
                      _createElementVNode("option", { value: "LU" }, "Luxembourg", -1),
                      _createElementVNode("option", { value: "MO" }, "Macao", -1),
                      _createElementVNode("option", { value: "MK" }, " Macedonia, the former Yugoslav Republic of ", -1),
                      _createElementVNode("option", { value: "MG" }, "Madagascar", -1),
                      _createElementVNode("option", { value: "MW" }, "Malawi", -1),
                      _createElementVNode("option", { value: "MY" }, "Malaysia", -1),
                      _createElementVNode("option", { value: "MV" }, "Maldives", -1),
                      _createElementVNode("option", { value: "ML" }, "Mali", -1),
                      _createElementVNode("option", { value: "MT" }, "Malta", -1),
                      _createElementVNode("option", { value: "MH" }, "Marshall Islands", -1),
                      _createElementVNode("option", { value: "MQ" }, "Martinique", -1),
                      _createElementVNode("option", { value: "MR" }, "Mauritania", -1),
                      _createElementVNode("option", { value: "MU" }, "Mauritius", -1),
                      _createElementVNode("option", { value: "YT" }, "Mayotte", -1),
                      _createElementVNode("option", { value: "MX" }, "Mexico", -1),
                      _createElementVNode("option", { value: "FM" }, "Micronesia, Federated States of", -1),
                      _createElementVNode("option", { value: "MD" }, "Moldova, Republic of", -1),
                      _createElementVNode("option", { value: "MC" }, "Monaco", -1),
                      _createElementVNode("option", { value: "MN" }, "Mongolia", -1),
                      _createElementVNode("option", { value: "ME" }, "Montenegro", -1),
                      _createElementVNode("option", { value: "MS" }, "Montserrat", -1),
                      _createElementVNode("option", { value: "MA" }, "Morocco", -1),
                      _createElementVNode("option", { value: "MZ" }, "Mozambique", -1),
                      _createElementVNode("option", { value: "MM" }, "Myanmar", -1),
                      _createElementVNode("option", { value: "NA" }, "Namibia", -1),
                      _createElementVNode("option", { value: "NR" }, "Nauru", -1),
                      _createElementVNode("option", { value: "NP" }, "Nepal", -1),
                      _createElementVNode("option", { value: "NL" }, "Netherlands", -1),
                      _createElementVNode("option", { value: "NC" }, "New Caledonia", -1),
                      _createElementVNode("option", { value: "NZ" }, "New Zealand", -1),
                      _createElementVNode("option", { value: "NI" }, "Nicaragua", -1),
                      _createElementVNode("option", { value: "NE" }, "Niger", -1),
                      _createElementVNode("option", { value: "NG" }, "Nigeria", -1),
                      _createElementVNode("option", { value: "NU" }, "Niue", -1),
                      _createElementVNode("option", { value: "NF" }, "Norfolk Island", -1),
                      _createElementVNode("option", { value: "MP" }, "Northern Mariana Islands", -1),
                      _createElementVNode("option", { value: "NO" }, "Norway", -1),
                      _createElementVNode("option", { value: "OM" }, "Oman", -1),
                      _createElementVNode("option", { value: "PK" }, "Pakistan", -1),
                      _createElementVNode("option", { value: "PW" }, "Palau", -1),
                      _createElementVNode("option", { value: "PS" }, "Palestinian Territory, Occupied", -1),
                      _createElementVNode("option", { value: "PA" }, "Panama", -1),
                      _createElementVNode("option", { value: "PG" }, "Papua New Guinea", -1),
                      _createElementVNode("option", { value: "PY" }, "Paraguay", -1),
                      _createElementVNode("option", { value: "PE" }, "Peru", -1),
                      _createElementVNode("option", { value: "PH" }, "Philippines", -1),
                      _createElementVNode("option", { value: "PN" }, "Pitcairn", -1),
                      _createElementVNode("option", { value: "PL" }, "Poland", -1),
                      _createElementVNode("option", { value: "PT" }, "Portugal", -1),
                      _createElementVNode("option", { value: "PR" }, "Puerto Rico", -1),
                      _createElementVNode("option", { value: "QA" }, "Qatar", -1),
                      _createElementVNode("option", { value: "RE" }, "Réunion", -1),
                      _createElementVNode("option", { value: "RO" }, "Romania", -1),
                      _createElementVNode("option", { value: "RU" }, "Russian Federation", -1),
                      _createElementVNode("option", { value: "RW" }, "Rwanda", -1),
                      _createElementVNode("option", { value: "BL" }, "Saint Barthélemy", -1),
                      _createElementVNode("option", { value: "SH" }, " Saint Helena, Ascension and Tristan da Cunha ", -1),
                      _createElementVNode("option", { value: "KN" }, "Saint Kitts and Nevis", -1),
                      _createElementVNode("option", { value: "LC" }, "Saint Lucia", -1),
                      _createElementVNode("option", { value: "MF" }, "Saint Martin (French part)", -1),
                      _createElementVNode("option", { value: "PM" }, "Saint Pierre and Miquelon", -1),
                      _createElementVNode("option", { value: "VC" }, "Saint Vincent and the Grenadines", -1),
                      _createElementVNode("option", { value: "WS" }, "Samoa", -1),
                      _createElementVNode("option", { value: "SM" }, "San Marino", -1),
                      _createElementVNode("option", { value: "ST" }, "Sao Tome and Principe", -1),
                      _createElementVNode("option", { value: "SA" }, "Saudi Arabia", -1),
                      _createElementVNode("option", { value: "SN" }, "Senegal", -1),
                      _createElementVNode("option", { value: "RS" }, "Serbia", -1),
                      _createElementVNode("option", { value: "SC" }, "Seychelles", -1),
                      _createElementVNode("option", { value: "SL" }, "Sierra Leone", -1),
                      _createElementVNode("option", { value: "SG" }, "Singapore", -1),
                      _createElementVNode("option", { value: "SX" }, "Sint Maarten (Dutch part)", -1),
                      _createElementVNode("option", { value: "SK" }, "Slovakia", -1),
                      _createElementVNode("option", { value: "SI" }, "Slovenia", -1),
                      _createElementVNode("option", { value: "SB" }, "Solomon Islands", -1),
                      _createElementVNode("option", { value: "SO" }, "Somalia", -1),
                      _createElementVNode("option", { value: "ZA" }, "South Africa", -1),
                      _createElementVNode("option", { value: "GS" }, " South Georgia and the South Sandwich Islands ", -1),
                      _createElementVNode("option", { value: "KR" }, "South Korea", -1),
                      _createElementVNode("option", { value: "SS" }, "South Sudan", -1),
                      _createElementVNode("option", { value: "ES" }, "Spain", -1),
                      _createElementVNode("option", { value: "LK" }, "Sri Lanka", -1),
                      _createElementVNode("option", { value: "SD" }, "Sudan", -1),
                      _createElementVNode("option", { value: "SR" }, "Suriname", -1),
                      _createElementVNode("option", { value: "SJ" }, "Svalbard and Jan Mayen", -1),
                      _createElementVNode("option", { value: "SZ" }, "Swaziland", -1),
                      _createElementVNode("option", { value: "SE" }, "Sweden", -1),
                      _createElementVNode("option", { value: "CH" }, "Switzerland", -1),
                      _createElementVNode("option", { value: "SY" }, "Syrian Arab Republic", -1),
                      _createElementVNode("option", { value: "TW" }, "Taiwan, Province of China", -1),
                      _createElementVNode("option", { value: "TJ" }, "Tajikistan", -1),
                      _createElementVNode("option", { value: "TZ" }, "Tanzania, United Republic of", -1),
                      _createElementVNode("option", { value: "TH" }, "Thailand", -1),
                      _createElementVNode("option", { value: "TL" }, "Timor-Leste", -1),
                      _createElementVNode("option", { value: "TG" }, "Togo", -1),
                      _createElementVNode("option", { value: "TK" }, "Tokelau", -1),
                      _createElementVNode("option", { value: "TO" }, "Tonga", -1),
                      _createElementVNode("option", { value: "TT" }, "Trinidad and Tobago", -1),
                      _createElementVNode("option", { value: "TN" }, "Tunisia", -1),
                      _createElementVNode("option", { value: "TR" }, "Turkey", -1),
                      _createElementVNode("option", { value: "TM" }, "Turkmenistan", -1),
                      _createElementVNode("option", { value: "TC" }, "Turks and Caicos Islands", -1),
                      _createElementVNode("option", { value: "TV" }, "Tuvalu", -1),
                      _createElementVNode("option", { value: "UG" }, "Uganda", -1),
                      _createElementVNode("option", { value: "UA" }, "Ukraine", -1),
                      _createElementVNode("option", { value: "AE" }, "United Arab Emirates", -1),
                      _createElementVNode("option", { value: "GB" }, "United Kingdom", -1),
                      _createElementVNode("option", { value: "US" }, "United States", -1),
                      _createElementVNode("option", { value: "UY" }, "Uruguay", -1),
                      _createElementVNode("option", { value: "UZ" }, "Uzbekistan", -1),
                      _createElementVNode("option", { value: "VU" }, "Vanuatu", -1),
                      _createElementVNode("option", { value: "VE" }, "Venezuela, Bolivarian Republic of", -1),
                      _createElementVNode("option", { value: "VN" }, "Vietnam", -1),
                      _createElementVNode("option", { value: "VI" }, "Virgin Islands", -1),
                      _createElementVNode("option", { value: "WF" }, "Wallis and Futuna", -1),
                      _createElementVNode("option", { value: "EH" }, "Western Sahara", -1),
                      _createElementVNode("option", { value: "YE" }, "Yemen", -1),
                      _createElementVNode("option", { value: "ZM" }, "Zambia", -1),
                      _createElementVNode("option", { value: "ZW" }, "Zimbabwe", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_ErrorMessage, { name: "country" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Address Line 1", -1)),
                  _createVNode(_component_Field, {
                    class: "form-control form-control-solid",
                    placeholder: "",
                    name: "address1",
                    modelValue: _ctx.newAddressData.address1,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAddressData.address1) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_ErrorMessage, { name: "address1" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Address Line 2", -1)),
                  _createVNode(_component_Field, {
                    class: "form-control form-control-solid",
                    placeholder: "",
                    name: "address2",
                    modelValue: _ctx.newAddressData.address2,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newAddressData.address2) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_ErrorMessage, { name: "address2" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _cache[16] || (_cache[16] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Town", -1)),
                  _createVNode(_component_Field, {
                    class: "form-control form-control-solid",
                    placeholder: "",
                    name: "town",
                    modelValue: _ctx.newAddressData.town,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAddressData.town) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_ErrorMessage, { name: "town" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "State / Province", -1)),
                    _createVNode(_component_Field, {
                      class: "form-control form-control-solid",
                      placeholder: "",
                      name: "state",
                      modelValue: _ctx.newAddressData.state,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newAddressData.state) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createVNode(_component_ErrorMessage, { name: "state" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _cache[18] || (_cache[18] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Post Code", -1)),
                    _createVNode(_component_Field, {
                      class: "form-control form-control-solid",
                      placeholder: "",
                      name: "postCode",
                      modelValue: _ctx.newAddressData.postCode,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newAddressData.postCode) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _createVNode(_component_ErrorMessage, { name: "postCode" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", _hoisted_38, [
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "me-5" }, [
                      _createElementVNode("label", { class: "fs-5 fw-bold" }, "Use as a billing adderess?"),
                      _createElementVNode("div", { class: "fs-7 fw-bold text-gray-400" }, " If you need more info, please check budget planning ")
                    ], -1)),
                    _createElementVNode("label", _hoisted_39, [
                      _createVNode(_component_Field, {
                        class: "form-check-input",
                        name: "billing",
                        type: "checkbox",
                        value: "1",
                        checked: "checked"
                      }),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("div", _hoisted_41, [
                          _createVNode(_component_ErrorMessage, { name: "billing" })
                        ])
                      ]),
                      _cache[19] || (_cache[19] = _createElementVNode("span", { class: "form-check-label fw-bold text-gray-400" }, " Yes ", -1))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_42, [
              _cache[22] || (_cache[22] = _createElementVNode("button", {
                type: "reset",
                id: "kt_modal_new_address_cancel",
                class: "btn btn-white me-3"
              }, " Discard ", -1)),
              _createElementVNode("button", _hoisted_43, _cache[21] || (_cache[21] = [
                _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1),
                _createElementVNode("span", { class: "indicator-progress" }, [
                  _createTextVNode(" Please wait... "),
                  _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                ], -1)
              ]), 512)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ])
    ])
  ], 512))
}